import './index.css';
import { TimeTick, DateTime, GenerateMenuItem, ForcastGet } from './App';


TimeTick(); 
DateTime(); 
GenerateMenuItem(); 



let ForcastCity = new URLSearchParams(window.location.search).get('city')

if(ForcastCity){
    ForcastGet(ForcastCity)
    setInterval(function(){ ForcastGet(ForcastCity); }, 300000);
}

setInterval(function(){ TimeTick(); DateTime(); }, 1000);
